import { ComponentRef, EditorSDK } from '@wix/platform-editor-sdk';
import { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import { getPanelUrl } from '@wix/yoshi-flow-editor/build/cjs/exports/utils';
import { SINGLE_PLAN_HELP_ARTICLE_ID } from '../../../../constants';

export interface InitialData {
  componentRef: ComponentRef;
}

export async function openPlanSettingsPanel(params: {
  editorSDK: EditorSDK;
  componentRef: ComponentRef;
  flowAPI: EditorScriptFlowAPI;
}) {
  const { editorSDK, componentRef, flowAPI } = params;
  return editorSDK.editor.openComponentPanel(
    '',
    {
      title: flowAPI.translations.t('blocks.plan-list-settings-panel.title'),
      url: getPanelUrl('PlanListWidget', 'PlanSettings'),
      height: flowAPI.environment.isClassicEditor ? 165 : 167,
      width: 288,
      componentRef,
      initialData: {
        componentRef,
      } satisfies InitialData,
      helpId: SINGLE_PLAN_HELP_ARTICLE_ID,
    },
    (token) => {
      editorSDK.editor.showPanelPreloader(token);
    },
  );
}
