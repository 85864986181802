import { ElementCategoryData, ElementData } from '@wix/platform-editor-sdk';
import { PerksWidgetRole, PlanWidgetRole, PricingWidgetRole } from '@wix/pricing-plans-common/blocks';
import { FlowAPI } from '@wix/yoshi-flow-editor';

enum CategoryId {
  Info = 'info',
  Benefits = 'benefits',
}

export const getCategoriesData = (t: FlowAPI['translations']['t']): ElementCategoryData[] => [
  {
    id: CategoryId.Info,
    title: t('blocks.elements-panel.info'),
  },
  {
    id: CategoryId.Benefits,
    title: t('blocks.elements-panel.benefits'),
  },
];

export const getElementsData = (t: FlowAPI['translations']['t']): ElementData[] => {
  const elements = [
    {
      label: t('blocks.elements-panel.image'),
      identifier: { role: PlanWidgetRole.ImageContainer },
      categoryId: CategoryId.Info,
      tooltipData: {
        content: t('blocks.elements-panel.image-tooltip'),
      },
    },
    {
      label: t('blocks.elements-panel.badge'),
      identifier: { role: PlanWidgetRole.RibbonWidget },
      categoryId: CategoryId.Info,
    },
    {
      label: t('blocks.elements-panel.plan-name'),
      identifier: { role: PlanWidgetRole.PlanName },
      categoryId: CategoryId.Info,
    },
    {
      label: t('blocks.elements-panel.price'),
      identifier: { role: PlanWidgetRole.PricingWidget },
      categoryId: CategoryId.Info,
    },
    {
      label: t('blocks.elements-panel.setup-fee'),
      identifier: { role: PricingWidgetRole.SetupFee },
      categoryId: CategoryId.Info,
    },
    {
      label: t('blocks.elements-panel.description'),
      identifier: { role: PlanWidgetRole.Description },
      categoryId: CategoryId.Info,
    },
    {
      label: t('blocks.elements-panel.plan-duration'),
      identifier: { role: PlanWidgetRole.PlanDuration },
      categoryId: CategoryId.Info,
    },
    {
      label: t('blocks.elements-panel.button'),
      identifier: { role: PlanWidgetRole.Button },
      categoryId: CategoryId.Info,
    },
    {
      label: t('blocks.elements-panel.content-divider'),
      identifier: { role: PlanWidgetRole.ContentDividerContainer },
      categoryId: CategoryId.Info,
    },
    {
      label: t('blocks.elements-panel.benefits-section'),
      identifier: { role: PlanWidgetRole.PerksWidget },
      categoryId: CategoryId.Benefits,
    },
    {
      label: t('blocks.elements-panel.icon'),
      identifier: { role: PerksWidgetRole.PerkIcon },
      categoryId: CategoryId.Benefits,
    },
    {
      label: t('blocks.elements-panel.perk-divider'),
      identifier: { role: PerksWidgetRole.PerkDivider },
      categoryId: CategoryId.Benefits,
    },
  ];

  return elements.map((element, i) => ({ ...element, index: i }));
};
