import { ComponentRef, EditorSDK } from '@wix/platform-editor-sdk';
import { PlanWidgetRole, SinglePlanPresetId, SinglePlanWidgetRole } from '@wix/pricing-plans-common/blocks';
import { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { getPanelUrl } from '@wix/yoshi-flow-editor/utils';
import { PLAN_PRESETS, PresetCategory } from '../../blocks-widgets-editor/layout/single-plan';
import { makeUnselectable } from '../../blocks-widgets-editor/manifest-utils';
import { SINGLE_PLAN_HELP_ARTICLE_ID } from '../../constants';
import { singlePlanThumbnailsClassic, singlePlanThumbnailsStudio } from '../../layout-thumbnails';

export const getWidgetManifest: GetWidgetManifestFn = (builder, editorSDK, flowAPI) => {
  const t = flowAPI.translations.t;
  builder.set({ displayName: t('blocks.label.single-plan') });

  builder.behavior().set({ duplicatable: true, resizable: true });
  builder.configureConnectedComponents(SinglePlanWidgetRole.MultiStateBox, makeUnselectable);
  builder.configureConnectedComponents(SinglePlanWidgetRole.StateSpinner, makeUnselectable);
  builder.configureConnectedComponents(SinglePlanWidgetRole.StateEmpty, makeUnselectable);
  builder.configureConnectedComponents(SinglePlanWidgetRole.StateLoaded, makeUnselectable);
  builder.configureConnectedComponents(PlanWidgetRole.PlanWidget, (planBuilder) => {
    makeUnselectable(planBuilder);
    planBuilder.set({ displayName: t('blocks.label.single-plan') });
  });

  builder
    .gfpp()
    .set('mainAction1', {
      label: t('blocks.gfpp.single-plan-settings'),
      onClick: (e) => {
        openSettingsPanel({ editorSDK, flowAPI, componentRef: e.detail.componentRef });
      },
    })
    .set('help', { id: SINGLE_PLAN_HELP_ARTICLE_ID })
    .set('design', { target: { role: PlanWidgetRole.PlanWidget } })
    .set('add', { target: { role: PlanWidgetRole.PlanWidget } })
    .set('connect', { behavior: 'HIDE' })
    .set('layout', { target: { role: PlanWidgetRole.PlanWidget } })
    .set('presets', { target: { role: PlanWidgetRole.PlanWidget } });

  builder.configureWidgetDesign((designBuilder) => {
    designBuilder.set({ title: t('blocks.design.title') });
    designBuilder.tabs().addTab((tabBuilder) => {
      tabBuilder.set({ label: t('blocks.plan-card.background-tab-label') });
      tabBuilder.groups().set({ roles: [PlanWidgetRole.PlanContainer] });
    });
  });

  builder.configureWidgetPresets((presetsBuilder) => {
    const presetIds = [
      SinglePlanPresetId.vertical,
      SinglePlanPresetId.vertical_fb,
      SinglePlanPresetId.vertical_tt_d,
      SinglePlanPresetId.vertical_fb_tt_d,
      SinglePlanPresetId.vertical_no_image,
      SinglePlanPresetId.vertical_fb_no_image,
      SinglePlanPresetId.horizontal,
      SinglePlanPresetId.horizontal_fb,
      SinglePlanPresetId.horizontal_tt_d,
      SinglePlanPresetId.horizontal_fb_tt_d,
      SinglePlanPresetId.horizontal_no_image,
      SinglePlanPresetId.horizontal_fb_no_image,
    ] as const;
    const presetCategories: { id: PresetCategory; name: string }[] = [
      { id: 'vertical', name: flowAPI.translations.t('blocks.single-plan.presets.vertical') },
      { id: 'horizontal', name: flowAPI.translations.t('blocks.single-plan.presets.horizontal') },
    ];
    const presetThumbnails = flowAPI.environment.isClassicEditor
      ? singlePlanThumbnailsClassic
      : singlePlanThumbnailsStudio;
    presetsBuilder.setCategories(presetCategories);
    presetsBuilder.setPresets(
      presetIds.map((presetId) => {
        const presetData = PLAN_PRESETS[presetId];
        return {
          id: presetId,
          categoryId: presetData.category,
          src: presetThumbnails[presetId],
        };
      }),
    );
  });

  builder
    .gfpp('mobile')
    .set('mainAction1', { behavior: 'HIDE' })
    .set('layout', { target: { role: PlanWidgetRole.PlanWidget } })
    .set('presets', { behavior: 'HIDE' });
};

function openSettingsPanel(params: { editorSDK: EditorSDK; componentRef: ComponentRef; flowAPI: EditorScriptFlowAPI }) {
  const { editorSDK, componentRef, flowAPI } = params;
  return editorSDK.editor.openComponentPanel(
    '',
    {
      title: flowAPI.translations.t('blocks.settings-panel.title'),
      url: getPanelUrl('SinglePlanWidget', 'SettingsPanel'),
      height: 200,
      width: 300,
      componentRef,
      initialData: {
        componentRef,
      },
      helpId: SINGLE_PLAN_HELP_ARTICLE_ID,
    },
    (token) => {
      editorSDK.editor.showPanelPreloader(token);
    },
  );
}
