import { PlanWidgetRole } from '@wix/pricing-plans-common/blocks';
import { TPA_EXPERIMENTS } from '@wix/pricing-plans-common/experiments';
import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { makeUnselectable } from '../../blocks-widgets-editor/manifest-utils';
import { SINGLE_PLAN_HELP_ARTICLE_ID } from '../../constants';
import { getSiteBrand } from '../../utils/get-site-brand';
import { getParentWidget } from '../../utils/widget';
import { openChangeRibbonTextPanel } from '../Plan/editor.controller';

export const getWidgetManifest: GetWidgetManifestFn = (builder, editorSDK, flowAPI) => {
  const t = flowAPI.translations.t;

  builder.set({ displayName: t('blocks.label.ribbon') });
  builder.gfpp().set('help', { id: SINGLE_PLAN_HELP_ARTICLE_ID });
  builder.configureConnectedComponents(PlanWidgetRole.RibbonContainer, makeUnselectable);
  if (flowAPI.experiments.enabled(TPA_EXPERIMENTS.FIX_STUDIO_BLOCKS_PLAN_CARD) && getSiteBrand() === 'studio') {
    builder.configureWidgetDesign((designBuilder) => {
      designBuilder.tabs().addTab((tabBuilder) => {
        tabBuilder.set({ label: t('blocks.ribbon.background-tab-label') });
        tabBuilder.groups().set({ roles: [PlanWidgetRole.RibbonContainer] });
      });
    });
  }

  builder.configureConnectedComponents(PlanWidgetRole.RibbonText, (ribbonTextBuilder) => {
    ribbonTextBuilder.set({ displayName: t('blocks.ribbon-design.text') });
    ribbonTextBuilder
      .behavior()
      .set({ closed: { hideFromHierarchy: false, selectable: true }, dataEditOptions: 'TEXT_STYLE_ONLY' });
    ribbonTextBuilder
      .gfpp()
      .set('mainAction2', {
        label: t('blocks.ribbon.edit-text'),
        onClick: async (e) =>
          openChangeRibbonTextPanel({
            editorSDK,
            componentRef: (await getParentWidget({ editorSDK, componentRef: e.detail.componentRef }))!,
            flowAPI,
          }),
      })
      .set('connect', { behavior: 'HIDE' });
  });
};
