import { EditorSDK, EventType, PageData, PageRef } from '@wix/platform-editor-sdk';
import { TpaPageId } from '@wix/pricing-plans-router-utils';
import { retry } from '@wix/pricing-plans-utils/http';
import type { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import pricingPlans from '../../.application.json';
import { captureEditorException } from './editor';
import { installRouter } from './installRouter';

const appDefinitionId = pricingPlans.appDefinitionId;
const TOKEN = '';

export async function installSplitPages(editorSDK: EditorSDK, flowAPI: EditorScriptFlowAPI, firstInstall: boolean) {
  if (firstInstall) {
    await splitPagesMigration(flowAPI, editorSDK);
    await installRouter(flowAPI, editorSDK);
  } else {
    const routers = await editorSDK.routers.getAll(TOKEN);
    const pages = await editorSDK.pages.getApplicationPages(TOKEN);
    if (routers.length > 0 && pages.length < 4) {
      await splitPagesMigration(flowAPI, editorSDK);
      await installRouter(flowAPI, editorSDK);
    }
  }

  await setPageStates(editorSDK);

  editorSDK.addEventListener(EventType.appVisitedInDashboard, async () => {
    await editorSDK.editor.routers.refresh(TOKEN);
    await editorSDK.tpa.app.refreshApp(TOKEN);
  });
}

function toRef(page: PageData): PageRef {
  return { id: page.id!, type: 'DESKTOP' };
}

export async function setPageStates(sdk: EditorSDK) {
  const pages = await sdk.pages.getApplicationPages(TOKEN);
  const state: Record<string, PageRef[]> = {
    checkout: pages.filter(({ tpaPageId }) => tpaPageId === TpaPageId.Checkout).map(toRef),
    paywall: pages.filter(({ tpaPageId }) => tpaPageId === TpaPageId.Paywall).map(toRef),
    thank_you: pages.filter(({ tpaPageId }) => tpaPageId === TpaPageId.ThankYou).map(toRef),
  };
  if (Object.values(state).some((statePages) => statePages.length)) {
    await sdk.pages.setState(TOKEN, { state });
  }
}

export async function splitPagesMigration(flowAPI: EditorScriptFlowAPI, sdk: EditorSDK) {
  try {
    flowAPI.fedops.interactionStarted('router_split_pages');
    await markPackagePickerManaged(sdk);
    await changePackagePickerSlug(sdk, 'list');
    await retry({ times: 1, delay: 500 }, () => installPagesIfNeeded(sdk));
    flowAPI.fedops.interactionEnded('router_split_pages');
  } catch (e) {
    captureEditorException(flowAPI, e, {
      interactionTag: 'router_split_pages',
      print: true,
    });
  }
}

async function installPagesIfNeeded(sdk: EditorSDK) {
  const pages = await sdk.pages.getApplicationPages(TOKEN);
  const tpaPageIds = pages.map((page) => page.tpaPageId);

  if (!tpaPageIds.includes(TpaPageId.Paywall)) {
    const component = await installPage(sdk, TpaPageId.Paywall);
    if ('pageRef' in component) {
      await sdk.document.pages.data.update('', { pageRef: component.pageRef, data: { indexable: false } });
    }
  }

  if (!tpaPageIds.includes(TpaPageId.ThankYou)) {
    await installPage(sdk, TpaPageId.ThankYou);
  }

  if (!tpaPageIds.includes(TpaPageId.Checkout)) {
    await installPage(sdk, TpaPageId.Checkout);
  }
}

function installPage(sdk: EditorSDK, tpaPageId: TpaPageId) {
  return sdk.document.tpa.add.component(TOKEN, {
    appDefinitionId,
    managingAppDefId: appDefinitionId,
    componentType: 'PAGE',
    page: {
      pageId: tpaPageId,
      isHidden: true,
      shouldNavigate: false,
    },
  });
}

async function markPackagePickerManaged(sdk: EditorSDK) {
  const pages = await sdk.pages.data.getAll(TOKEN);
  for (const page of pages) {
    if (page.appDefinitionId === appDefinitionId && !page.managingAppDefId && page.id) {
      await sdk.pages.data.update(TOKEN, {
        pageRef: { id: page.id, type: 'DESKTOP' },
        data: { managingAppDefId: appDefinitionId, indexable: true },
      });
    }
  }
}

async function changePackagePickerSlug(sdk: EditorSDK, slug: string) {
  const pages = await sdk.pages.data.getAll(TOKEN);
  for (const page of pages) {
    if (page.tpaPageId === 'membership_plan_picker_tpa' && page.id) {
      await sdk.pages.data.update(TOKEN, {
        pageRef: { id: page.id, type: 'DESKTOP' },
        data: { pageUriSEO: slug },
      });
    }
  }
}
