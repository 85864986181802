import { ComponentRef, EditorSDK } from '@wix/platform-editor-sdk';
import { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import { getPanelUrl } from '@wix/yoshi-flow-editor/utils';
import { SINGLE_PLAN_HELP_ARTICLE_ID } from '../../../../constants';

export function openPlanLayoutPanel(params: {
  editorSDK: EditorSDK;
  componentRef: ComponentRef;
  flowAPI: EditorScriptFlowAPI;
  isMobile?: boolean;
  isPlanList?: boolean;
}) {
  const { editorSDK, componentRef, flowAPI, isMobile } = params;

  return editorSDK.editor.openComponentPanel(
    '',
    {
      title: flowAPI.translations.t('blocks.layouts-panel.title'),
      url: getPanelUrl('Plan', 'LayoutPanel'),
      height: 190,
      width: 288,
      componentRef,
      initialData: {
        componentRef,
        isEditorX: flowAPI.environment.isEditorX,
        isMobile,
      },
      helpId: SINGLE_PLAN_HELP_ARTICLE_ID,
    },
    (token) => {
      editorSDK.editor.showPanelPreloader(token);
    },
  );
}
