import eventually from 'wix-eventually';
import { listPublicPlans } from '@wix/ambassador-pricing-plans-v2-plan/http';
import { EditorSDK, WidgetInstallationType, ComponentRef } from '@wix/platform-editor-sdk';
import { PlanListPresetId } from '@wix/pricing-plans-common/blocks';
import { TPA_EXPERIMENTS } from '@wix/pricing-plans-common/experiments';
import { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import PlanListWidget from '../components/PlanListWidget/.component.json';
import { PlanListWidgetProps } from '../components/PlanListWidget/model';
import { compressUuidArray } from '../services/uuid-compression';
import { PlanListBlocksInteraction } from '../types/PlanListBlocksFedops';
import { toError } from '../utils/errors';
import { getSiteBrand } from '../utils/get-site-brand';
import { setPlanCardListDefaultsOnStudio } from '../utils/plan-list-widget';
import { openProgressBar, assertPlanListWidgetExists, closeProgressBar, updateProgressBar } from '../utils/widget';

export async function addPlanListBlocksWidget(editorSDK: EditorSDK, flowAPI: EditorScriptFlowAPI) {
  const removeMobilePresetEnabled = flowAPI.experiments.enabled(TPA_EXPERIMENTS.REMOVE_PLAN_LIST_MOBILE_PRESET);
  const scopedPresets = removeMobilePresetEnabled
    ? {
        desktop: {
          layout: PlanListPresetId.vertical,
          style: PlanListPresetId.vertical,
        },
      }
    : {
        desktop: {
          layout: PlanListPresetId.vertical,
          style: PlanListPresetId.vertical,
        },
        mobile: {
          layout: PlanListPresetId.mobile,
          style: PlanListPresetId.mobile,
        },
      };

  return editorSDK.document.application.appStudioWidgets.addWidget('', {
    widgetId: PlanListWidget.id,
    installationType: WidgetInstallationType.Closed,
    layout: {
      width: 980,
      height: 1080,
      x: 0,
      y: 100,
    },
    scopedPresets,
    layouts: {
      componentLayout: {
        type: 'ComponentLayout',
        hidden: false,
        height: {
          type: 'auto',
        },
        minHeight: {
          type: 'px',
          value: 500,
        },
        maxWidth: {
          type: 'percentage',
          value: 100,
        },
        width: {
          type: 'px',
          value: 980,
        },
      },
      itemLayout: {
        id: '',
        alignSelf: 'center',
        justifySelf: 'center',
        type: 'GridItemLayout',
        gridArea: {
          rowStart: 1,
          rowEnd: 2,
          columnStart: 1,
          columnEnd: 2,
        },
        margins: {
          top: {
            type: 'px',
            value: 0,
          },
          left: {
            type: 'px',
            value: 0,
          },
        },
      },
      containerLayout: {
        type: 'GridContainerLayout',
        columns: [{ type: 'fr', value: 1 }],
        rows: [{ type: 'fr', value: 1 }],
      },
    },
  });
}

export async function onPlanListWidgetAddedToStage(params: {
  editorSDK: EditorSDK;
  componentRef: ComponentRef;
  flowAPI: EditorScriptFlowAPI;
}) {
  const { editorSDK, componentRef, flowAPI } = params;
  flowAPI.fedops.interactionStarted(PlanListBlocksInteraction.WidgetAdded);
  try {
    openProgressBar({
      editorSDK,
      title: flowAPI.translations.t('blocks.plan-list.add-widget-progress-bar.title'),
      steps: 3,
    });
    /*
    When adding the widget from Add Panel, it takes a bit for it to be
    available through editor SDK. We need to make sure that the component ref
    exists before proceeding with widget initialization
    */
    flowAPI.fedops.interactionStarted(PlanListBlocksInteraction.AssertWidgetExists);
    await eventually(() => assertPlanListWidgetExists(editorSDK, componentRef), {
      interval: 100,
      timeout: 5000,
    });
    flowAPI.fedops.interactionEnded(PlanListBlocksInteraction.AssertWidgetExists);
    updateProgressBar({ editorSDK, step: 1 });

    await initNewPlanListWidget({
      editorSDK,
      componentRef,
      flowAPI,
    });
    flowAPI.fedops.interactionEnded(PlanListBlocksInteraction.WidgetAdded);
    closeProgressBar({ editorSDK });
  } catch (e) {
    console.log(e);
    editorSDK.components.remove('', { componentRef });
    closeProgressBar({ editorSDK, isError: true });
    flowAPI.errorMonitor.captureException(toError(e));
  }
}

async function initNewPlanListWidget(params: {
  editorSDK: EditorSDK;
  componentRef: ComponentRef;
  flowAPI: EditorScriptFlowAPI;
}) {
  const { editorSDK, flowAPI, componentRef } = params;
  const removeMobilePresetEnabled = flowAPI.experiments.enabled(TPA_EXPERIMENTS.REMOVE_PLAN_LIST_MOBILE_PRESET);
  const [widgetRef] = await editorSDK.document.components.getChildren('', { componentRef });

  await assignPlansToWidget({ editorSDK, flowAPI, widgetRef });

  updateProgressBar({ editorSDK, step: 2 });

  if (removeMobilePresetEnabled) {
    if (getSiteBrand() === 'studio') {
      await setPlanCardListDefaultsOnStudio({ editorSDK, componentRef: widgetRef });
    }
  }

  // TODO: Uncomment when specs.membership.RemoveMobilePresetPlanCardList is merged
  // await collapseRibbonOnDefaultAndCustomPlan(editorSDK, widgetRef);
  // await collapseDisclaimer(editorSDK, widgetRef);
  // updateProgressBar({ editorSDK, step: 3 });

  await editorSDK.application.livePreview.refresh('', {
    shouldFetchData: true,
    source: 'PLAN_LIST_WIDGET_ADDED',
  });
}

async function assignPlansToWidget(params: {
  editorSDK: EditorSDK;
  widgetRef: ComponentRef;
  flowAPI: EditorScriptFlowAPI;
}) {
  const { editorSDK, widgetRef, flowAPI } = params;
  const planIds = await getPlanIds({ flowAPI, limit: 3 });
  if (planIds.length) {
    const compressedPlanIds = compressUuidArray(planIds ?? []);
    await editorSDK.document.application.appStudioWidgets.props.set('', {
      widgetRef,
      newProps: {
        planIds: compressedPlanIds.join(','),
        // TODO: Uncomment when specs.membership.RemoveMobilePresetPlanCardList is merged
        // highlightedPlanIds: compressedPlanIds.length > 1 ? compressedPlanIds[1] : '',
        isPlanList: true,
      } satisfies Partial<PlanListWidgetProps>,
    });
  }
}

async function getPlanIds(params: { flowAPI: EditorScriptFlowAPI; limit: number }): Promise<string[]> {
  const { flowAPI, limit } = params;
  try {
    const {
      data: { plans },
    } = await flowAPI.httpClient.request(listPublicPlans({ limit }));
    return plans?.map((plan) => plan.id!) ?? [];
  } catch (e) {
    return [];
  }
}

// TODO: Uncomment when specs.membership.RemoveMobilePresetPlanCardList is merged
// async function collapseRibbonOnDefaultAndCustomPlan(editorSDK: EditorSDK, planListWidgetRef: ComponentRef) {
//   const planWidgets = await Promise.all([
//     findComponentByRole({
//       editorSDK,
//       controllerRef: planListWidgetRef,
//       role: PlanListWidgetRole.PlanVariantDefaultWidget,
//     }),
//     findComponentByRole({
//       editorSDK,
//       controllerRef: planListWidgetRef,
//       role: PlanListWidgetRole.PlanVariantCustomWidget,
//     }),
//   ]);

//   const ribbonWidgets = await Promise.all(
//     planWidgets.map(
//       (planWidget) =>
//         planWidget && findComponentByRole({ editorSDK, controllerRef: planWidget, role: PlanWidgetRole.RibbonWidget }),
//     ),
//   );

//   return Promise.all(
//     ribbonWidgets.map(
//       async (ribbonWidget) =>
//         ribbonWidget &&
//         editorSDK.document.components.refComponents.collapseReferredComponent('token', {
//           componentRef: await getContainerRef(editorSDK, ribbonWidget),
//         }),
//     ),
//   );
// }

// async function collapseDisclaimer(editorSDK: EditorSDK, planListWidgetRef: ComponentRef) {
//   const disclaimerElement = await findComponentByRole({
//     editorSDK,
//     controllerRef: planListWidgetRef,
//     role: PlanListWidgetRole.Disclaimer,
//   });

//   if (!disclaimerElement) {
//     return;
//   }

//   return editorSDK.document.components.refComponents.collapseReferredComponent('token', {
//     componentRef: disclaimerElement,
//   });
// }
