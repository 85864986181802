import { pickBy } from 'lodash';
import { Alignment, ComponentRef, EditorSDK } from '@wix/platform-editor-sdk';
import { getParentWidget } from '../../utils/widget';
import { ElementSizeData } from '../Plan/panels/Layout/utils';

export type BenefitsDesignVariables = {
  benefitsFirstColumnWidth: ElementSizeData<'fr', 0 | 1>;
  benefitsLastColumnWidth: ElementSizeData<'fr', 0 | 1>;
  iconPosition: 2 | 4;
  textPosition: 2 | 4;
  benefitsTopPadding: ElementSizeData<'px'>;
  benefitsBottomPadding: ElementSizeData<'px'>;
  benefitsHorizontalPaddings: ElementSizeData<'px'>;
  iconSpacing: ElementSizeData<'px'>;
  iconSize: ElementSizeData<'px'>;
  perkMarginTop: ElementSizeData<'px'>;
  perkMarginBottom: ElementSizeData<'px'>;
  iconTopMargin: ElementSizeData<'px'>;
  iconAlignment: Extract<Alignment, 'start' | 'center'>;
};

export function setBenefitsDesignVariables(params: {
  editorSDK: EditorSDK;
  widgetRef: ComponentRef;
  newValues: Partial<BenefitsDesignVariables>;
}) {
  const { editorSDK, widgetRef, newValues } = params;
  const definedNewValues = pickBy(newValues, (value) => value !== undefined);
  return editorSDK.document.application.appStudioWidgets.designVariables.set('', {
    widgetRef,
    newValues: definedNewValues,
  });
}

export function removeIconSpacing(editorSDK: EditorSDK, widgetRef: ComponentRef) {
  return setBenefitsDesignVariables({
    editorSDK,
    widgetRef,
    newValues: {
      iconSpacing: {
        unit: 'px',
        value: 0,
      },
    },
  });
}

const DEFAULT_ICON_SPACING = 8;
export function setIconSpacingToDefault(editorSDK: EditorSDK, widgetRef: ComponentRef) {
  return setBenefitsDesignVariables({
    editorSDK,
    widgetRef,
    newValues: {
      iconSpacing: {
        unit: 'px',
        value: DEFAULT_ICON_SPACING,
      },
    },
  });
}

export function changeBenefitsSidePadding(params: { editorSDK: EditorSDK; widgetRef: ComponentRef; value: number }) {
  const { editorSDK, widgetRef, value } = params;
  return setBenefitsDesignVariables({
    editorSDK,
    widgetRef,
    newValues: {
      benefitsHorizontalPaddings: {
        unit: 'px',
        value,
      },
    },
  });
}

export async function getBenefitsDesignVariables(
  editorSDK: EditorSDK,
  widgetRef: ComponentRef,
): Promise<BenefitsDesignVariables> {
  const variables = await editorSDK.document.application.appStudioWidgets.designVariables.get('', {
    widgetRef,
  });

  return variables as BenefitsDesignVariables;
}

export async function onPerkTextStyleChange(editorSDK: EditorSDK, componentRef: ComponentRef) {
  const benefitsWidget = await getParentWidget({ editorSDK, componentRef });
  if (!benefitsWidget) {
    return;
  }

  const { iconSize, iconTopMargin, iconAlignment } = await getBenefitsDesignVariables(editorSDK, benefitsWidget);
  const newTopMargin = await calculateIconTopMargin({
    editorSDK,
    iconSizeValue: iconSize.value,
    currentMargin: iconTopMargin.value,
    iconAlignment,
    perkTextRef: componentRef,
  });
  if (iconTopMargin.value !== newTopMargin) {
    await setBenefitsDesignVariables({
      editorSDK,
      widgetRef: benefitsWidget,
      newValues: { iconTopMargin: { unit: 'px', value: newTopMargin } },
    });
  }
}

export async function calculateIconTopMargin(params: {
  editorSDK: EditorSDK;
  iconSizeValue: number;
  currentMargin: number;
  iconAlignment: BenefitsDesignVariables['iconAlignment'];
  perkTextRef: ComponentRef;
}): Promise<number> {
  const { editorSDK, perkTextRef, iconSizeValue, currentMargin, iconAlignment } = params;
  if (iconAlignment === 'center') {
    return 0;
  }

  const textStyle = await editorSDK.document.components.style.get('', { componentRef: perkTextRef });
  const lineHeight =
    textStyle.style.properties.lineHeight === 'normal' ? 1.2 : parseFloat(textStyle.style.properties.lineHeight);
  const fontSize = parseInt(textStyle.style.properties.fontSize, 10);
  if (isNaN(lineHeight) || isNaN(fontSize)) {
    return currentMargin;
  }

  const textLineSize = lineHeight * fontSize;
  return Math.round((textLineSize - iconSizeValue) / 2);
}
