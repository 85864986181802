import { SinglePlanPresetId } from '@wix/pricing-plans-common/blocks';
import { PlanDesignVariables } from '../../components/Plan/panels/Layout/utils';

export type PresetCategory = 'vertical' | 'horizontal';

export interface Preset {
  id: SinglePlanPresetId;
  category: PresetCategory;
  width: number;
  height: number;
  layoutDefaults: Pick<PlanDesignVariables, 'imageHeight'>;
}

export enum RootPresetId {
  Desktop = 'variants-l9mt9pib1',
  Mobile = 'variants-lbbv6kl4',
}

export const isValidSinglePlanPresetId = (id: string): id is SinglePlanPresetId => {
  return Object.values(SinglePlanPresetId).includes(id as SinglePlanPresetId);
};

export const PLAN_PRESETS: Record<SinglePlanPresetId, Preset> = {
  [SinglePlanPresetId.vertical]: {
    id: SinglePlanPresetId.vertical,
    category: 'vertical',
    width: 300,
    height: 200,
    layoutDefaults: {
      imageHeight: {
        unit: 'px',
        value: 160,
      },
    },
  },
  [SinglePlanPresetId.vertical_tt_d]: {
    id: SinglePlanPresetId.vertical_tt_d,
    category: 'vertical',
    width: 300,
    height: 200,
    layoutDefaults: {
      imageHeight: {
        unit: 'px',
        value: 160,
      },
    },
  },
  [SinglePlanPresetId.vertical_no_image]: {
    id: SinglePlanPresetId.vertical_no_image,
    category: 'vertical',
    width: 300,
    height: 200,
    layoutDefaults: {
      imageHeight: {
        unit: 'px',
        value: 160,
      },
    },
  },
  [SinglePlanPresetId.vertical_fb]: {
    id: SinglePlanPresetId.vertical_fb,
    category: 'vertical',
    width: 300,
    height: 200,
    layoutDefaults: {
      imageHeight: {
        unit: 'px',
        value: 160,
      },
    },
  },
  [SinglePlanPresetId.vertical_fb_tt_d]: {
    id: SinglePlanPresetId.vertical_fb_tt_d,
    category: 'vertical',
    width: 300,
    height: 200,
    layoutDefaults: {
      imageHeight: {
        unit: 'px',
        value: 160,
      },
    },
  },
  [SinglePlanPresetId.vertical_fb_no_image]: {
    id: SinglePlanPresetId.vertical_fb_no_image,
    category: 'vertical',
    width: 304,
    height: 200,
    layoutDefaults: {
      imageHeight: {
        unit: 'px',
        value: 0,
      },
    },
  },
  [SinglePlanPresetId.horizontal]: {
    id: SinglePlanPresetId.horizontal,
    category: 'horizontal',
    width: 980,
    height: 410,
    layoutDefaults: {
      imageHeight: {
        unit: 'percentage',
        value: 100,
      },
    },
  },
  [SinglePlanPresetId.horizontal_tt_d]: {
    id: SinglePlanPresetId.horizontal_tt_d,
    category: 'horizontal',
    width: 980,
    height: 410,
    layoutDefaults: {
      imageHeight: {
        unit: 'percentage',
        value: 100,
      },
    },
  },
  [SinglePlanPresetId.horizontal_no_image]: {
    id: SinglePlanPresetId.horizontal_no_image,
    category: 'horizontal',
    width: 560,
    height: 410,
    layoutDefaults: {
      imageHeight: {
        unit: 'percentage',
        value: 100,
      },
    },
  },
  [SinglePlanPresetId.horizontal_fb]: {
    id: SinglePlanPresetId.horizontal_fb,
    category: 'horizontal',
    width: 980,
    height: 410,
    layoutDefaults: {
      imageHeight: {
        unit: 'percentage',
        value: 100,
      },
    },
  },
  [SinglePlanPresetId.horizontal_fb_tt_d]: {
    id: SinglePlanPresetId.horizontal_fb_tt_d,
    category: 'horizontal',
    width: 980,
    height: 410,
    layoutDefaults: {
      imageHeight: {
        unit: 'percentage',
        value: 100,
      },
    },
  },
  [SinglePlanPresetId.horizontal_fb_no_image]: {
    id: SinglePlanPresetId.horizontal_fb_no_image,
    category: 'horizontal',
    width: 560,
    height: 410,
    layoutDefaults: {
      imageHeight: {
        unit: 'percentage',
        value: 100,
      },
    },
  },
  // Legacy presets are no longer shown in presets panel, but could be in use by older users
  [SinglePlanPresetId.LEGACY_vertical_tt_d]: {
    id: SinglePlanPresetId.LEGACY_vertical_tt_d,
    category: 'vertical',
    width: 300,
    height: 200,
    layoutDefaults: {
      imageHeight: {
        unit: 'percentage',
        value: 100,
      },
    },
  },
  [SinglePlanPresetId.LEGACY_vertical_fb_tt_d]: {
    id: SinglePlanPresetId.LEGACY_vertical_fb_tt_d,
    category: 'vertical',
    width: 300,
    height: 200,
    layoutDefaults: {
      imageHeight: {
        unit: 'percentage',
        value: 100,
      },
    },
  },
  [SinglePlanPresetId.LEGACY_horizontal_tt_d]: {
    id: SinglePlanPresetId.LEGACY_horizontal_tt_d,
    category: 'horizontal',
    width: 980,
    height: 410,
    layoutDefaults: {
      imageHeight: {
        unit: 'percentage',
        value: 100,
      },
    },
  },
  [SinglePlanPresetId.LEGACY_horizontal_fb_tt_d]: {
    id: SinglePlanPresetId.LEGACY_horizontal_fb_tt_d,
    category: 'horizontal',
    width: 980,
    height: 410,
    layoutDefaults: {
      imageHeight: {
        unit: 'percentage',
        value: 100,
      },
    },
  },
} as const;

export const MOBILE_PRESET_BY_SINGLE_PLAN_DESKTOP_PRESET_ID: Record<SinglePlanPresetId, SinglePlanPresetId> = {
  [SinglePlanPresetId.vertical]: SinglePlanPresetId.vertical,
  [SinglePlanPresetId.vertical_tt_d]: SinglePlanPresetId.vertical_tt_d,
  [SinglePlanPresetId.vertical_no_image]: SinglePlanPresetId.vertical_no_image,
  [SinglePlanPresetId.vertical_fb]: SinglePlanPresetId.vertical_fb,
  [SinglePlanPresetId.vertical_fb_tt_d]: SinglePlanPresetId.vertical_fb_tt_d,
  [SinglePlanPresetId.vertical_fb_no_image]: SinglePlanPresetId.vertical_fb_no_image,

  [SinglePlanPresetId.horizontal]: SinglePlanPresetId.vertical,
  [SinglePlanPresetId.horizontal_tt_d]: SinglePlanPresetId.vertical_tt_d,
  [SinglePlanPresetId.horizontal_no_image]: SinglePlanPresetId.vertical_no_image,
  [SinglePlanPresetId.horizontal_fb]: SinglePlanPresetId.vertical_fb,
  [SinglePlanPresetId.horizontal_fb_tt_d]: SinglePlanPresetId.vertical_fb_tt_d,
  [SinglePlanPresetId.horizontal_fb_no_image]: SinglePlanPresetId.vertical_fb_no_image,

  [SinglePlanPresetId.LEGACY_vertical_tt_d]: SinglePlanPresetId.LEGACY_vertical_tt_d,
  [SinglePlanPresetId.LEGACY_vertical_fb_tt_d]: SinglePlanPresetId.LEGACY_vertical_fb_tt_d,
  [SinglePlanPresetId.LEGACY_horizontal_tt_d]: SinglePlanPresetId.LEGACY_vertical_tt_d,
  [SinglePlanPresetId.LEGACY_horizontal_fb_tt_d]: SinglePlanPresetId.LEGACY_vertical_fb_tt_d,
};
