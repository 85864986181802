import { PlanListPresetId, SinglePlanPresetId } from '@wix/pricing-plans-common/blocks';
import { getStaticAssetUrl } from './utils/get-static-asset-url';

export const singlePlanThumbnailsLegacy = {
  [SinglePlanPresetId.vertical]: getStaticAssetUrl('preset-thumbnails/legacy-single-plan/default/vertical.png'),
  [SinglePlanPresetId.vertical_no_image]: getStaticAssetUrl(
    'preset-thumbnails/legacy-single-plan/default/vertical_no_image.png',
  ),
  [SinglePlanPresetId.LEGACY_vertical_tt_d]: getStaticAssetUrl(
    'preset-thumbnails/legacy-single-plan/default/vertical_tt_d.png',
  ),
  [SinglePlanPresetId.horizontal]: getStaticAssetUrl('preset-thumbnails/legacy-single-plan/horizontal/horizontal.png'),
  [SinglePlanPresetId.horizontal_no_image]: getStaticAssetUrl(
    'preset-thumbnails/legacy-single-plan/horizontal/horizontal_no_image.png',
  ),
  [SinglePlanPresetId.LEGACY_horizontal_tt_d]: getStaticAssetUrl(
    'preset-thumbnails/legacy-single-plan/horizontal/horizontal_tt_d.png',
  ),
  [SinglePlanPresetId.horizontal_fb]: getStaticAssetUrl(
    'preset-thumbnails/legacy-single-plan/horizontal-full-width/horizontal_fb.png',
  ),
  [SinglePlanPresetId.horizontal_fb_no_image]: getStaticAssetUrl(
    'preset-thumbnails/legacy-single-plan/horizontal-full-width/horizontal_fb_no_image.png',
  ),
  [SinglePlanPresetId.LEGACY_horizontal_fb_tt_d]: getStaticAssetUrl(
    'preset-thumbnails/legacy-single-plan/horizontal-full-width/horizontal_fb_tt_d.png',
  ),
  [SinglePlanPresetId.vertical_fb]: getStaticAssetUrl(
    'preset-thumbnails/legacy-single-plan/vertical-full-width/vertical_fb.png',
  ),
  [SinglePlanPresetId.vertical_fb_no_image]: getStaticAssetUrl(
    'preset-thumbnails/legacy-single-plan/vertical-full-width/vertical_fb_no_image.png',
  ),
  [SinglePlanPresetId.LEGACY_vertical_fb_tt_d]: getStaticAssetUrl(
    'preset-thumbnails/legacy-single-plan/vertical-full-width/vertical_fb_tt_d.png',
  ),
};

export const singlePlanThumbnailsClassic = {
  [SinglePlanPresetId.horizontal]: getStaticAssetUrl('preset-thumbnails/single-plan/classic/horizontal.png'),
  [SinglePlanPresetId.horizontal_fb]: getStaticAssetUrl('preset-thumbnails/single-plan/classic/horizontal_fb.png'),
  [SinglePlanPresetId.horizontal_fb_no_image]: getStaticAssetUrl(
    'preset-thumbnails/single-plan/classic/horizontal_fb_no_image.png',
  ),
  [SinglePlanPresetId.horizontal_fb_tt_d]: getStaticAssetUrl(
    'preset-thumbnails/single-plan/classic/horizontal_fb_tt_d.png',
  ),
  [SinglePlanPresetId.horizontal_no_image]: getStaticAssetUrl(
    'preset-thumbnails/single-plan/classic/horizontal_no_image.png',
  ),
  [SinglePlanPresetId.horizontal_tt_d]: getStaticAssetUrl('preset-thumbnails/single-plan/classic/horizontal_tt_d.png'),
  [SinglePlanPresetId.vertical]: getStaticAssetUrl('preset-thumbnails/single-plan/classic/vertical.png'),
  [SinglePlanPresetId.vertical_fb]: getStaticAssetUrl('preset-thumbnails/single-plan/classic/vertical_fb.png'),
  [SinglePlanPresetId.vertical_fb_no_image]: getStaticAssetUrl(
    'preset-thumbnails/single-plan/classic/vertical_fb_no_image.png',
  ),
  [SinglePlanPresetId.vertical_fb_tt_d]: getStaticAssetUrl(
    'preset-thumbnails/single-plan/classic/vertical_fb_tt_d.png',
  ),
  [SinglePlanPresetId.vertical_no_image]: getStaticAssetUrl(
    'preset-thumbnails/single-plan/classic/vertical_no_image.png',
  ),
  [SinglePlanPresetId.vertical_tt_d]: getStaticAssetUrl('preset-thumbnails/single-plan/classic/vertical_tt_d.png'),
};

export const singlePlanThumbnailsStudio = {
  [SinglePlanPresetId.horizontal]: getStaticAssetUrl('preset-thumbnails/single-plan/studio/horizontal.png'),
  [SinglePlanPresetId.horizontal_fb]: getStaticAssetUrl('preset-thumbnails/single-plan/studio/horizontal_fb.png'),
  [SinglePlanPresetId.horizontal_fb_no_image]: getStaticAssetUrl(
    'preset-thumbnails/single-plan/studio/horizontal_fb_no_image.png',
  ),
  [SinglePlanPresetId.horizontal_fb_tt_d]: getStaticAssetUrl(
    'preset-thumbnails/single-plan/studio/horizontal_fb_tt_d.png',
  ),
  [SinglePlanPresetId.horizontal_no_image]: getStaticAssetUrl(
    'preset-thumbnails/single-plan/studio/horizontal_no_image.png',
  ),
  [SinglePlanPresetId.horizontal_tt_d]: getStaticAssetUrl('preset-thumbnails/single-plan/studio/horizontal_tt_d.png'),
  [SinglePlanPresetId.vertical]: getStaticAssetUrl('preset-thumbnails/single-plan/studio/vertical.png'),
  [SinglePlanPresetId.vertical_fb]: getStaticAssetUrl('preset-thumbnails/single-plan/studio/vertical_fb.png'),
  [SinglePlanPresetId.vertical_fb_no_image]: getStaticAssetUrl(
    'preset-thumbnails/single-plan/studio/vertical_fb_no_image.png',
  ),
  [SinglePlanPresetId.vertical_fb_tt_d]: getStaticAssetUrl('preset-thumbnails/single-plan/studio/vertical_fb_tt_d.png'),
  [SinglePlanPresetId.vertical_no_image]: getStaticAssetUrl(
    'preset-thumbnails/single-plan/studio/vertical_no_image.png',
  ),
  [SinglePlanPresetId.vertical_tt_d]: getStaticAssetUrl('preset-thumbnails/single-plan/studio/vertical_tt_d.png'),
};

export const planListThumbnailsClassic = {
  [PlanListPresetId.horizontal]: getStaticAssetUrl('preset-thumbnails/plan-list/classic/horizontal.png'),
  [PlanListPresetId.horizontal_fb]: getStaticAssetUrl('preset-thumbnails/plan-list/classic/horizontal_fb.png'),
  [PlanListPresetId.horizontal_fb_no_image]: getStaticAssetUrl(
    'preset-thumbnails/plan-list/classic/horizontal_fb_no_image.png',
  ),
  [PlanListPresetId.horizontal_fb_tt_d]: getStaticAssetUrl(
    'preset-thumbnails/plan-list/classic/horizontal_fb_tt_d.png',
  ),
  [PlanListPresetId.horizontal_no_image]: getStaticAssetUrl(
    'preset-thumbnails/plan-list/classic/horizontal_no_image.png',
  ),
  [PlanListPresetId.horizontal_tt_d]: getStaticAssetUrl('preset-thumbnails/plan-list/classic/horizontal_tt_d.png'),
  [PlanListPresetId.vertical]: getStaticAssetUrl('preset-thumbnails/plan-list/classic/vertical.png'),
  [PlanListPresetId.vertical_fb]: getStaticAssetUrl('preset-thumbnails/plan-list/classic/vertical_fb.png'),
  [PlanListPresetId.vertical_fb_no_image]: getStaticAssetUrl(
    'preset-thumbnails/plan-list/classic/vertical_fb_no_image.png',
  ),
  [PlanListPresetId.vertical_fb_tt_d]: getStaticAssetUrl('preset-thumbnails/plan-list/classic/vertical_fb_tt_d.png'),
  [PlanListPresetId.vertical_no_image]: getStaticAssetUrl('preset-thumbnails/plan-list/classic/vertical_no_image.png'),
  [PlanListPresetId.vertical_tt_d]: getStaticAssetUrl('preset-thumbnails/plan-list/classic/vertical_tt_d.png'),
};

export const planListThumbnailsStudio = {
  [PlanListPresetId.horizontal]: getStaticAssetUrl('preset-thumbnails/plan-list/studio/horizontal.png'),
  [PlanListPresetId.horizontal_fb]: getStaticAssetUrl('preset-thumbnails/plan-list/studio/horizontal_fb.png'),
  [PlanListPresetId.horizontal_fb_no_image]: getStaticAssetUrl(
    'preset-thumbnails/plan-list/studio/horizontal_fb_no_image.png',
  ),
  [PlanListPresetId.horizontal_fb_tt_d]: getStaticAssetUrl('preset-thumbnails/plan-list/studio/horizontal_fb_tt_d.png'),
  [PlanListPresetId.horizontal_no_image]: getStaticAssetUrl(
    'preset-thumbnails/plan-list/studio/horizontal_no_image.png',
  ),
  [PlanListPresetId.horizontal_tt_d]: getStaticAssetUrl('preset-thumbnails/plan-list/studio/horizontal_tt_d.png'),
  [PlanListPresetId.vertical]: getStaticAssetUrl('preset-thumbnails/plan-list/studio/vertical.png'),
  [PlanListPresetId.vertical_fb]: getStaticAssetUrl('preset-thumbnails/plan-list/studio/vertical_fb.png'),
  [PlanListPresetId.vertical_fb_no_image]: getStaticAssetUrl(
    'preset-thumbnails/plan-list/studio/vertical_fb_no_image.png',
  ),
  [PlanListPresetId.vertical_fb_tt_d]: getStaticAssetUrl('preset-thumbnails/plan-list/studio/vertical_fb_tt_d.png'),
  [PlanListPresetId.vertical_no_image]: getStaticAssetUrl('preset-thumbnails/plan-list/studio/vertical_no_image.png'),
  [PlanListPresetId.vertical_tt_d]: getStaticAssetUrl('preset-thumbnails/plan-list/studio/vertical_tt_d.png'),
};
