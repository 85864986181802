import { ComponentRef, EditorSDK } from '@wix/platform-editor-sdk';
import { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import { getPanelUrl } from '@wix/yoshi-flow-editor/utils';

export const openTitleLayoutPanel = (params: {
  editorSDK: EditorSDK;
  componentRef: ComponentRef;
  flowAPI: EditorScriptFlowAPI;
}) => {
  const { editorSDK, componentRef, flowAPI } = params;

  return editorSDK.editor.openComponentPanel(
    '',
    {
      title: flowAPI.translations.t('blocks.plan-list.title-layout.title'),
      url: getPanelUrl('PlanListWidget', 'TitleLayoutPanel'),
      componentRef,
      width: 288,
      height: 230,
      initialData: {
        componentRef,
      },
    },
    (token) => editorSDK.editor.showPanelPreloader(token),
  );
};
