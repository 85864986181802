import { EditorSDK, TransactionError } from '@wix/platform-editor-sdk';
import { TPA_EXPERIMENTS } from '@wix/pricing-plans-common/experiments';
import { FlowAPI } from '@wix/yoshi-flow-editor';

const TOKEN = '';

export async function setPermissionsPage(editorSDK: EditorSDK, flowAPI: FlowAPI, appDefinitionId: string) {
  const pageId = flowAPI.experiments.enabled(TPA_EXPERIMENTS.INSTALL_SPLIT_PAGES)
    ? await findPageId(editorSDK, appDefinitionId, 'Paywall')
    : await findPageId(editorSDK, appDefinitionId, 'membership_plan_picker_tpa');
  if (pageId) {
    const noPermissionsPageId = await editorSDK.document.siteMembers.getCustomNoPermissionsPageId(TOKEN);
    if (noPermissionsPageId !== pageId) {
      flowAPI.fedops.interactionStarted('es_set_permissions_page_tx');
      await runAndWaitForApprovalWithRetry(editorSDK, () =>
        editorSDK.document.siteMembers.setCustomNoPermissionsPageId(TOKEN, { pageId }),
      );
      flowAPI.fedops.interactionEnded('es_set_permissions_page_tx');
    }
  }
}

async function findPageId(editorSDK: EditorSDK, appDefinitionId: string, tpaPageId: string) {
  const pages = await editorSDK.document.pages.data.getAll(TOKEN);
  const page = pages?.find(
    (candidate) => candidate.appDefinitionId === appDefinitionId && candidate.tpaPageId === tpaPageId,
  );
  return page?.id;
}

async function runAndWaitForApprovalWithRetry(editorSDK: EditorSDK, action: () => void) {
  try {
    await editorSDK.document.transactions.runAndWaitForApproval(TOKEN, action);
  } catch (e) {
    if (await editorSDK.document.transactions.isConflictError(TOKEN, e as TransactionError)) {
      await editorSDK.document.transactions.runAndWaitForApproval(TOKEN, action);
    } else {
      throw e;
    }
  }
}
