export enum InstallInteractions {
  Integration = 'es_integration',
  MembersAreaInstall = 'es_members_area_install',
  IntegratedAppsInstall = 'es_integrated_apps_install',
  PpIntegratedAppsInstall = 'es_pp_integrated_apps_install',
}

export enum AutopilotInstallInteractions {
  Integration = 'om_es_integration',
  MembersAreaInstall = 'om_es_members_area_install',
  IntegratedAppsInstall = 'om_es_integrated_apps_install',
  PpIntegratedAppsInstall = 'om_es_pp_integrated_apps_install',
}
