import { PerksWidgetRole } from '@wix/pricing-plans-common/blocks';
import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { configureTextElement, makeUnselectable } from '../../blocks-widgets-editor/manifest-utils';
import { SINGLE_PLAN_HELP_ARTICLE_ID } from '../../constants';

export const getWidgetManifest: GetWidgetManifestFn = (builder, editorSDK, flowAPI) => {
  const t = flowAPI.translations.t;
  builder.set({ displayName: t('blocks.label.benefits') });
  builder.gfpp().set('help', { id: SINGLE_PLAN_HELP_ARTICLE_ID });

  builder.configureConnectedComponents(PerksWidgetRole.PerksContainer, makeUnselectable);
  builder.configureConnectedComponents(PerksWidgetRole.PerkItem, makeUnselectable);
  builder.configureConnectedComponents(PerksWidgetRole.PerkContent, makeUnselectable);

  builder.configureConnectedComponents(PerksWidgetRole.PerkText, (textBuilder) => {
    textBuilder.set({ displayName: t('blocks.label.benefit-text') });
    configureTextElement({
      builder: textBuilder,
      editorSDK,
      flowAPI,
      editTextLabel: t('blocks.benefits.text.edit'),
      designTextLabel: t('blocks.benefits.text.design'),
    });
  });

  builder.configureConnectedComponents(PerksWidgetRole.PerkIcon, (iconBuilder) => {
    iconBuilder.set({ displayName: t('blocks.label.benefit-icon') });
    iconBuilder
      .gfpp()
      .set('help', { id: SINGLE_PLAN_HELP_ARTICLE_ID })
      // TODO: Unhide when panel modularization is supported for icon element
      .set('settings', { behavior: 'HIDE' })
      .set('connect', { behavior: 'HIDE' })
      .set('link', { behavior: 'HIDE' });
  });
};
