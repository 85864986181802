import { EditorSDK, ComponentRef } from '@wix/platform-editor-sdk';
import { TPA_EXPERIMENTS } from '@wix/pricing-plans-common/experiments';
import { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import { PlanListWidgetProps } from '../components/PlanListWidget/model';
import { getSiteBrand } from '../utils/get-site-brand';
import { setPlanCardListDefaultsOnStudio } from '../utils/plan-list-widget';
import {
  getContainerRef,
  getRootWidget,
  setWidgetProps,
  updateWidgetSizeClassic,
  updateWidgetSizeEditorX,
} from '../utils/widget';
import {
  isHorizontalPlanListPreset,
  isValidPlanListPresetId,
  MOBILE_PRESET_BY_PLAN_LIST_DESKTOP_PRESET_ID,
} from './layout/plan-list';
import {
  isValidSinglePlanPresetId,
  MOBILE_PRESET_BY_SINGLE_PLAN_DESKTOP_PRESET_ID,
  PLAN_PRESETS,
} from './layout/single-plan';
import { biReportPresetChange } from './manifest-utils';

export async function onGlobalDesignPresetChanged(params: {
  eventPayload: { preset: string; componentRef: ComponentRef };
  editorSDK: EditorSDK;
  flowAPI: EditorScriptFlowAPI;
}) {
  const { eventPayload, editorSDK, flowAPI } = params;
  const { preset, componentRef } = eventPayload;
  // TODO: Add BI event
  if (isValidPlanListPresetId(preset)) {
    const removeMobilePresetEnabled = flowAPI.experiments.enabled(TPA_EXPERIMENTS.REMOVE_PLAN_LIST_MOBILE_PRESET);
    await editorSDK.document.components.refComponents.removeOverrides('', {
      componentRef: await getContainerRef(editorSDK, componentRef),
      inclusions: { variables: '*' },
    });
    if (removeMobilePresetEnabled) {
      if (getSiteBrand() === 'studio') {
        await setPlanCardListDefaultsOnStudio({ editorSDK, componentRef });
      }
    } else {
      editorSDK.document.application.appStudioWidgets.changePreset('', {
        componentRef: await getContainerRef(editorSDK, componentRef),
        layoutPresetId: MOBILE_PRESET_BY_PLAN_LIST_DESKTOP_PRESET_ID[preset],
        stylePresetId: MOBILE_PRESET_BY_PLAN_LIST_DESKTOP_PRESET_ID[preset],
        context: {
          viewport: 'MOBILE',
        },
      });
    }
    setWidgetProps<PlanListWidgetProps>({
      editorSDK,
      widgetRef: componentRef,
      newProps: { isHorizontal: isHorizontalPlanListPreset(preset) },
    });
  }

  if (isValidSinglePlanPresetId(preset)) {
    await editorSDK.document.components.refComponents.removeOverrides('', {
      componentRef: await getContainerRef(editorSDK, componentRef),
      inclusions: { variables: '*' },
    });
    const rootWidget = await getRootWidget(editorSDK, componentRef);
    const rootWidgetParentRef = await getContainerRef(editorSDK, rootWidget);
    const presetConfig = PLAN_PRESETS[preset];
    const isFullWidth = await editorSDK.components.isFullWidth('', { componentRef: rootWidgetParentRef });

    if (!isFullWidth) {
      if (flowAPI.environment.isClassicEditor) {
        updateWidgetSizeClassic({
          editorSDK,
          containerRef: rootWidgetParentRef,
          width: presetConfig.width,
          height: presetConfig.height,
        });
      } else {
        updateWidgetSizeEditorX({
          editorSDK,
          containerRef: rootWidgetParentRef,
          width: { type: 'px', value: presetConfig.width },
          height: presetConfig.category === 'vertical' ? { type: 'auto' } : { type: 'px', value: presetConfig.height },
        });
      }
    }
    editorSDK.document.application.appStudioWidgets.changePreset('', {
      componentRef: await getContainerRef(editorSDK, componentRef),
      layoutPresetId: MOBILE_PRESET_BY_SINGLE_PLAN_DESKTOP_PRESET_ID[preset],
      stylePresetId: MOBILE_PRESET_BY_SINGLE_PLAN_DESKTOP_PRESET_ID[preset],
      context: {
        viewport: 'MOBILE',
      },
    });

    biReportPresetChange({ editorSDK, rootWidget, flowAPI, presetId: preset });
  }
}
