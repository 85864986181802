import { EditorSDK, PageData, RouterRef } from '@wix/platform-editor-sdk';
import { TpaPageId, PageRoleId } from '@wix/pricing-plans-router-utils';
import { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import { captureEditorException } from './editor';

const TOKEN = '';
const ROUTER_PREFIX = 'pricing-plans';

// Allows to identify page by it's purpose/role instead of requiring to use page IDs which are unique per site.
const PackagePickerRole = 'pricing_plans' as const;
const CheckoutRole = 'checkout' as const;
const ThankYouRole = 'thank_you' as const;
const PaywallRole = 'paywall' as const;

// TODO: Paywall page does not work if connected to the router.
const isPaywallConnected = false;

const tpaPageIdRole: Record<TpaPageId, PageRoleId> = {
  [TpaPageId.PackagePicker]: PackagePickerRole,
  [TpaPageId.Checkout]: CheckoutRole,
  [TpaPageId.ThankYou]: ThankYouRole,
  [TpaPageId.Paywall]: PaywallRole,
};

export async function installRouter(flowAPI: EditorScriptFlowAPI, sdk: EditorSDK) {
  try {
    let routerRef = await sdk.document.routers.getByPrefix(TOKEN, { prefix: ROUTER_PREFIX });
    const pages = await sdk.document.pages.getApplicationPages(TOKEN);
    const packagePickerSlug = findUri(pages, 'membership_plan_picker_tpa', 'list');
    if (!routerRef) {
      const config = { slugs: Object.fromEntries(pages.map((page) => [page.tpaPageId, page.pageUriSEO])) };
      routerRef = await sdk.document.routers.add(TOKEN, { prefix: ROUTER_PREFIX, config });
    }
    await ensureRouterConnectedPages(sdk, routerRef, packagePickerSlug);
  } catch (e) {
    captureEditorException(flowAPI, e, {
      interactionTag: 'router_install',
    });
  }
}

async function ensureRouterConnectedPages(sdk: EditorSDK, routerRef: RouterRef, packagePickerSlug: string) {
  const routerData = await sdk.document.routers.get(TOKEN, { routerRef });
  const installedPageRoles = routerData.pages.flatMap((page) => page.pageRoles);
  if (!installedPageRoles.includes(PackagePickerRole)) {
    await connectPackagePickerPageToRouter(sdk, routerRef, packagePickerSlug);
  }
  if (!installedPageRoles.includes(CheckoutRole)) {
    await connectCheckoutPageToRouter(sdk, routerRef);
  }
  if (!installedPageRoles.includes(ThankYouRole)) {
    await connectThankYouPageToRouter(sdk, routerRef);
  }
  if (isPaywallConnected && !installedPageRoles.includes(PaywallRole)) {
    await connectPaywallPageToRouter(sdk, routerRef);
  }
}

export async function uninstallRouter(sdk: EditorSDK) {
  const routerRef = await sdk.document.routers.getByPrefix(TOKEN, { prefix: ROUTER_PREFIX });
  if (routerRef) {
    await sdk.document.routers.remove(TOKEN, { routerRef });
  }
}

function findUri(pages: PageData[], tpaPageId: string, fallback: string): string {
  return pages.find((page) => page.tpaPageId === tpaPageId)?.pageUriSEO ?? fallback;
}

const connectThankYouPageToRouter = async (sdk: EditorSDK, routerRef: RouterRef) =>
  connectPageToRouter(TpaPageId.ThankYou, sdk, routerRef);

const connectCheckoutPageToRouter = async (sdk: EditorSDK, routerRef: RouterRef) =>
  connectPageToRouter(TpaPageId.Checkout, sdk, routerRef);

const connectPaywallPageToRouter = async (sdk: EditorSDK, routerRef: RouterRef) =>
  connectPageToRouter(TpaPageId.Paywall, sdk, routerRef);

const connectPackagePickerPageToRouter = async (sdk: EditorSDK, routerRef: RouterRef, slug: string) =>
  connectPageToRouter(TpaPageId.PackagePicker, sdk, routerRef, slug);

async function connectPageToRouter(tpaPageId: TpaPageId, sdk: EditorSDK, routerRef: RouterRef, slug?: string) {
  await sdk.document.routers.pages.connect(TOKEN, {
    routerRef,
    pageRef: await sdk.document.tpa.getPageRefByTPAPageId(TOKEN, { tpaPageId }),
    pageRoles: [tpaPageIdRole[tpaPageId]],
    slug,
  });
}
