import { ComponentRef, EditorSDK } from '@wix/platform-editor-sdk';
import { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import { getPanelUrl } from '@wix/yoshi-flow-editor/utils';

export interface InitialData {
  componentRef: ComponentRef;
}

export async function openPlanListSettingsPanel(params: {
  editorSDK: EditorSDK;
  componentRef: ComponentRef;
  flowAPI: EditorScriptFlowAPI;
}) {
  const { editorSDK, componentRef, flowAPI } = params;
  return editorSDK.editor.openComponentPanel(
    '',
    {
      title: flowAPI.translations.t('blocks.plan-list-settings.title'),
      url: getPanelUrl('PlanListWidget', 'Settings'),
      componentRef,
      initialData: { componentRef },
      width: 288,
      height: 453,
    },
    (token) => {
      editorSDK.editor.showPanelPreloader(token);
    },
  );
}
