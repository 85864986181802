import { EditorSDK, DesignVariables, ComponentRef } from '@wix/platform-editor-sdk';

const HORIZONTAL_PADDING = { value: 24, unit: 'px' };
const TITLE_SECOND_COLUMN = { value: 70.6, unit: 'percentage' };
const SUBTITLE_SECOND_COLUMN = { value: 64.7, unit: 'percentage' };

/**
 * Overrides design varibales to take into account additional different default margins
 * in installation between classic and studio.
 */
export async function setPlanCardListDefaultsOnStudio({
  editorSDK,
  componentRef,
}: {
  editorSDK: EditorSDK;
  componentRef: ComponentRef;
}) {
  const oldDesignVariables = await editorSDK?.document.application.appStudioWidgets.designVariables.get('', {
    widgetRef: componentRef,
  });

  const newValues = {
    ...oldDesignVariables,
    horizontalPadding: HORIZONTAL_PADDING,
    titleSecondColumnWidth: TITLE_SECOND_COLUMN,
    subtitleSecondColumnWidth: SUBTITLE_SECOND_COLUMN,
  } as DesignVariables;

  return editorSDK?.document.application.appStudioWidgets.designVariables.set('', {
    widgetRef: componentRef,
    newValues,
  });
}
